import React from 'react';
import { Link } from 'gatsby-plugin-react-i18next';

import { PATH } from '@src/types/aliases';

import * as S from './Breadcrumbs.S';

export const Breadcrumbs: React.FC<{
  root: { text: string; link: PATH };
  current: { text: string };
}> = ({ root, current }) => (
  <S.Container>
    <S.RootLink to={root.link}>{root.text}</S.RootLink>
    <S.Separator>›</S.Separator>
    <S.Current>{current.text}</S.Current>
  </S.Container>
);
