import React, { useState } from 'react';
import { PageProps, graphql } from 'gatsby';
import { useI18next } from 'gatsby-plugin-react-i18next';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Embed } from 'hyvor-talk-react';

import { Shell } from '@components/Shell/Shell';
import { SEO } from '@src/components/SEO/SEO';
import { Content } from '@src/components/Content/Content';
import { Heading } from '@src/components/Heading/Heading';
import { useWhichLanguage } from '@src/hooks/useWhichLanguage';
import { PaintingExtended } from '@src/types/paintings';
import { Breadcrumbs } from '@src/components/Breadcrumbs/Breadcrumbs';
import { Routes } from '@src/constants/routes';

import * as S from './Painting.S';
import { MediaModal } from '@src/components/MediaModal/MediaModal';
import { MediaModalImage, MediaModalVideo } from '@src/types/media';
import { YoutubeVideoID } from '@src/types/aliases';
import { youtubeVideoIdSeparator } from '@src/constants/video';
import { Gallery } from '@src/components/Gallery/Gallery';
import { GalleryItemSuperType } from '@src/types/gallery';
import { Button } from '@src/components/Button/Button';
import { gtagEvents } from '@src/constants/gtagEvents';
import {
  HYVOR_TALK_ID_PREFIX,
  HYVOR_TALK_WEBSITE_ID,
} from '@src/constants/hyvor';

const PaintingPage: React.FC<
  PageProps & {
    data: {
      api: {
        painting: PaintingExtended | null;
      };
      site: {
        siteMetadata: {
          siteUrl: string;
        };
      };
    };
  }
> = ({
  data: {
    api: { painting },
    site: {
      siteMetadata: { siteUrl },
    },
  },
  location,
}) => {
  const { t, language } = useI18next();
  const { isEnglish } = useWhichLanguage();
  const [isMediaModalOpen, setIsMediaModalOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  if (!painting || !painting.largeImagePathSharp) {
    return null;
  }
  const openMediaModal = () => {
    setIsMediaModalOpen(true);
  };

  const closeMediaModal = () => {
    setIsMediaModalOpen(false);
  };
  const {
    largeImagePathSharp,
    enDescription,
    svDescription,
    title,
    width,
    height,
    sold,
    youtubeVideoIds,
  } = painting;
  const image = getImage(largeImagePathSharp.childImageSharp);

  const youtubeVideoIDsArray =
    youtubeVideoIds.trim() === ''
      ? []
      : youtubeVideoIds
          .trim()
          .split(youtubeVideoIdSeparator)
          .map((youtubeVideoID: YoutubeVideoID) => youtubeVideoID);

  const modalImages = [painting, ...painting.additionalImages].map(
    (entity): MediaModalImage => {
      const image = getImage(
        entity.largeImagePathSharp?.childImageSharp || null
      );
      const { svDescription, enDescription } = entity;
      const description = isEnglish ? enDescription : svDescription;
      return {
        path: entity.imagePath,
        imageElement: image ? (
          <GatsbyImage image={image} objectFit="contain" alt="" />
        ) : (
          <></>
        ),
        description: 'paintingID' in entity ? description : '',
      };
    }
  );

  const modalVideos = youtubeVideoIDsArray.map(
    (youtubeVideoID): MediaModalVideo => {
      return {
        youtubeVideoID,
      };
    }
  );

  const modalMediaItems = [...modalImages, ...modalVideos];

  const videoGalleryItems = youtubeVideoIDsArray.map(
    (videoID): GalleryItemSuperType => ({
      id: videoID,
      youtubeVideoID: videoID,
      pixelWidth: 320,
    })
  );

  const imageGalleryItems = painting.additionalImages.map(
    (image): GalleryItemSuperType => {
      const { id, thumbnailImagePathSharp } = image;
      return {
        id,
        thumbnailImagePathSharp: thumbnailImagePathSharp,
        pixelWidth: thumbnailImagePathSharp?.childImageSharp.gatsbyImageData
          .width as number,
      };
    }
  );

  const galleryItems = [...imageGalleryItems, ...videoGalleryItems];

  const jumboClick = () => {
    setCurrentImageIndex(0);
    openMediaModal();
  };

  return (
    <Shell>
      <SEO
        locationOrigin={siteUrl}
        locationPathname={location.pathname}
        openGraphImagePath={
          painting.largeImagePathSharp.childImageSharp.gatsbyImageData.images
            .fallback?.src
        }
        openGraphImageWidth={
          painting.largeImagePathSharp.childImageSharp.gatsbyImageData.width
        }
        openGraphImageHeight={
          painting.largeImagePathSharp.childImageSharp.gatsbyImageData.height
        }
        title={`${title} - ${t('ArtPiece')}`}
        description={`${title}: ${t('PaintingByLenaLotten')}`}
      />
      <Content>
        <Container>
          <Grid
            container
            spacing={0}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item xs={12} sm={10} md={9}>
              <Breadcrumbs
                root={{ link: Routes.Index, text: t('Gallery') }}
                current={{ text: title }}
              />
              <Heading className="mt0" align="center">
                {title}
              </Heading>
              <Grid
                container
                spacing={0}
                alignItems="center"
                justifyContent="center"
              >
                {image && (
                  <div
                    onClick={jumboClick}
                    tabIndex={1}
                    onKeyDown={(e) => e.key === 'Enter' && jumboClick()}
                  >
                    <GatsbyImage
                      image={image}
                      alt=""
                      style={{ cursor: 'pointer' }}
                    />
                  </div>
                )}
              </Grid>

              <S.DimensionsAndSoldState>
                <S.Dimensions>
                  w {width} cm x h {height} cm
                </S.Dimensions>
                |
                <S.SoldState isSold={sold}>
                  {sold ? t('Sold') : t('ForSale')}
                </S.SoldState>
              </S.DimensionsAndSoldState>
              <S.Description>
                {isEnglish ? enDescription : svDescription}
              </S.Description>
              <S.GalleryContainer>
                <Gallery
                  pullOut
                  items={galleryItems}
                  onGalleryItemClick={(index) => {
                    setCurrentImageIndex(index + 1);
                    openMediaModal();
                  }}
                />
              </S.GalleryContainer>
              {typeof window !== 'undefined' &&
                (navigator.share || process.env.NODE_ENV === 'development') && (
                  <S.ShareAPIButtonContainer>
                    <Button
                      onClick={async () => {
                        try {
                          await navigator.share({ url: location.href });
                          typeof window !== 'undefined' &&
                            window.gtag('event', gtagEvents.sharePainting, {
                              url: location.href,
                            });
                        } catch (err) {}
                      }}
                    >
                      <i className="lni-share" style={{ fontSize: '12px' }} />
                      <span>{t('ShareArtPiece')}</span>
                    </Button>
                  </S.ShareAPIButtonContainer>
                )}
              <S.HyvorContainer>
                <Embed
                  language={language}
                  websiteId={HYVOR_TALK_WEBSITE_ID}
                  id={`${HYVOR_TALK_ID_PREFIX.painting}${painting.id}`}
                />
              </S.HyvorContainer>
            </Grid>
          </Grid>
        </Container>
      </Content>
      <MediaModal
        mediaItems={modalMediaItems}
        currentIndex={currentImageIndex}
        setCurrentIndex={setCurrentImageIndex}
        closeModal={closeMediaModal}
        isOpen={isMediaModalOpen}
      />
    </Shell>
  );
};

export default PaintingPage;

export const query = graphql`
  query ($language: String!, $slug: String!) {
    api {
      painting: paintingBySlug(slug: $slug) {
        id
        slug
        title
        itemOrder
        height
        width
        sold
        imagePath
        enDescription
        svDescription
        youtubeVideoIds
        largeImagePathSharp: imagePathSharp {
          childImageSharp {
            gatsbyImageData(
              height: 900
              placeholder: NONE
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        additionalImages {
          id
          paintingID
          enDescription
          svDescription
          itemOrder
          imagePath
          thumbnailImagePathSharp: imagePathSharp {
            childImageSharp {
              gatsbyImageData(
                height: 210
                placeholder: NONE
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
          largeImagePathSharp: imagePathSharp {
            childImageSharp {
              gatsbyImageData(
                height: 1000
                placeholder: NONE
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;
