import styled from 'styled-components';

export const Description = styled.p`
  font-style: italic;
  text-align: center;
`;

export const DimensionsAndSoldState = styled.p`
  text-align: center;
  font-size: 18px;
`;

export const Dimensions = styled.span`
  margin-right: 15px;
`;

export const SoldState = styled.span<{ isSold: boolean }>`
  margin-left: ${({ isSold }) => (isSold ? '30px' : '17px')};
  font-weight: 600;
  position: relative;

  &::after {
    position: absolute;
    left: -15px;
    content: '';
    display: block;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: ${({ isSold }) => (isSold ? '#f50057' : 'transparent')};
    top: 7px;
  }
`;

export const GalleryContainer = styled.div`
  margin-top: 30px;
`;

export const ShareAPIButtonContainer = styled.div`
  display: flex;
  margin-top: 15px;
`;

export const HyvorContainer = styled.div`
  margin-top: 10px;
`;
