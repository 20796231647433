import { Link } from 'gatsby-plugin-react-i18next';
import styled from 'styled-components';

export const Container = styled.div`
  margin-bottom: 40px;
  font-size: 16px;
  display: flex;
  align-items: center;

  @media screen and (pointer: coarse) {
    margin-bottom: 30px;
  }
`;

export const RootLink = styled(Link)`
  color: #000;
`;

export const Current = styled.span`
  text-decoration: underline;
`;

export const Separator = styled.span`
  position: relative;
  top: -1px;
  padding: 0 6px;
  font-size: 22px;
`;
